<template>
  <div class="container">
    <div class="mt-7">
      <div class="row">
        <div class="col mt-4 col-md-8 col-lg-6 mx-auto"><login /></div>
        <div class="col col-md-7 col-lg-6 d-none d-lg-flex">
          <img
            src="@/assets/img/ill/search_box.svg"
            style="max-width: 100%"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Login from "@/components/Login.vue";
export default {
  bodyClass: "login-page",
  components: {
    Login
  },
  metaInfo: () => ({
    title: "Log In"
  })
};
</script>
<style scoped></style>
