<template>
  <div class="card">
    <div class="card-body px-lg-5 py-lg-5">
      <h5 class="card-title text-center">LOG IN TO YOUR ACCOUNT</h5>
      <div v-if="$store.getters.login_message">
        <base-alert type="info" dismissible>{{
          $store.getters.login_message
        }}</base-alert>
      </div>
      <div v-if="not_active" class="not_active">
        <base-alert dismissible type="danger">
          Account is not verified</base-alert
        >
        <p>
          We need to verify your email address. We've sent an email to
          <strong>{{ user.email }}</strong> to verify your address. Please click
          the link in that email to continue. <br />
        </p>
        <p>
          If you cannot find your confirmation email anymore,
          <base-button
            style="text-transform: none"
            class="btn-round mt-2"
            @click="resendVerification()"
            >Resend Verification</base-button
          >
          <base-button
            @click="reLogIn()"
            type="login"
            class="ntt btn-round mt-2"
            >Log In</base-button
          >
          <base-alert
            dismissible
            class="mt-2"
            type="primary"
            v-if="verification_sent"
            >New verification link has been sent to
            <strong>{{ user.email }}</strong></base-alert
          >
          <base-alert v-if="resend_error" class="mt-3" type="danger">
            An error occurred. Could not send verification email.</base-alert
          >
        </p>
      </div>
      <validation-observer v-else ref="observer">
        <form role="form" @submit.prevent="login">
          <base-alert v-if="loginError" type="danger">
            <div slot="text">Invalid Username or Password</div>
          </base-alert>
          <base-alert v-if="networkError" type="warning">
            <div slot="text">Could not connect to server</div>
          </base-alert>
          <div class="mt-4 mb-4">
            <p>
              New user?
              <router-link class="mylink" to="/signup"
                >
                  Create an account</router-link
              >
            </p>
          </div>
          <div class="form-group mb-3">
            <validation-provider
              name="Email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <base-input
                name="email"
                v-model="user.email"
                placeholder="Email"
                :error="errors[0]"
              ></base-input>
            </validation-provider>
          </div>
          <div class="form-group">
            <validation-provider
              name="Password"
              rules="required|min:6"
              v-slot="{ errors }"
            >
              <base-input
                name="password"
                v-model="user.password"
                type="password"
                placeholder="Password"
                :error="errors[0]"
              ></base-input>
            </validation-provider>
          </div>
          <!-- <div class="custom-control-alternative custom-checkbox pl-0">
                    <base-checkbox v-model="remember_me" alternative
                      >Remember me</base-checkbox
                    >
                  </div> -->
          <div class="text-center">
            <p>
              <router-link class="mylink" to="/forgot-password">Forgot password?</router-link>
            </p>
          </div>
          <div class="text-center">
            <base-button
              block
              @click="login()"
              :disabled="loadings.button"
              type="login"
              class="my-4 ntt"
            >
              <spinner
                v-if="loadings.button"
                type="grow"
                class="spinner-grow-sm"
              ></spinner>
              <span v-else>Log In</span></base-button
            >
          </div>
          <input type="submit" style="display: none" />
        </form>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import Spinner from "@/components/Spinner";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  components: {
    Spinner,
    ValidationProvider,
    ValidationObserver
  },
  data: function() {
    return {
      user: { email: "", password: "" },
      loadings: {
        button: false
      },
      loginError: false,
      remember_me: true,
      not_active: false,
      verification_sent: false,
      resend_error: false,
      networkError: false
    };
  },
  mounted() {
    if (this.$route.query.next) {
      this.$store.commit("after_login", this.$route.query.next);
    }
  },
  methods: {
    login() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
        this.loadings.button = true;
        this.loginError = false;
        this.not_active = false;
        this.$axios
          .post("login/", this.user)
          .then(res => {
            this.loadings.button = false;
            this.$store.dispatch("login", {
              token: res.data,
              remember: this.remember_me
            });
            this.$router.push(this.$store.getters.after_login);
            this.$store.commit("after_login", "/account");
            this.$store.commit("login_message", null);
          })
          .catch(err => {
            this.loadings.button = false;
            if (!err.response) {
              this.networkError = true;
              setTimeout(() => {
                this.networkError = false;
              }, 5000);
            }
            if (err.response.data.code) {
              if (err.response.data.code == "not_active") {
                this.not_active = true;
                document
                  .getElementById("not_active")
                  .scrollIntoView({ behavior: "smooth" });
              }
            } else {
              this.loginError = true;
              setTimeout(() => {
                this.loginError = false;
              }, 5000);
            }
          });
      });
    },
    resendVerification() {
      this.verification_sent = false;
      this.resend_error = false;
      this.$axios
        .post("send-verification/", { email: this.user.email })
        .then(res => {
          if (res.data.code == "verification_sent") {
            this.verification_sent = true;
          }
        })
        .catch(err => {
          if (err.response) {
            this.resend_error = true;
          }
        });
    },
    reLogIn() {
      this.not_active = false;
    }
  }
};
</script>
<style></style>
